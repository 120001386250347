var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loading.list ? _c('SpinnerLogo') : _c('div', [_c('div', {
    staticClass: "row mb-4"
  }, [_c('div', {
    staticClass: "col-xl-4 col-lg-4 col-md-4 col-4"
  }, [_c('div', {
    staticClass: "font-w600 font-size-h5 mb-1"
  }, [_c('i', {
    staticClass: "fad fa-screwdriver-wrench text-primary mr-2"
  }), _vm._v("Templates")]), _c('p', {
    staticClass: "mb-3 text-muted font-w500 font-size-sm"
  }, [_vm._v("These are reports that have been saved as a template by you and your company users.")]), _c('div', {
    staticClass: "mb-5"
  }, [_vm.userTemplates.length === 0 ? _c('div', {
    staticClass: "alert alert-warning py-2 px-3"
  }, [_vm._v("No Templates")]) : _c('VerticalPills', {
    attrs: {
      "items": _vm.userTemplates,
      "item-key": "_id",
      "groups": _vm.groups.user,
      "alt": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('a', {
          staticClass: "nav-link",
          class: {
            active: _vm.selectedTemplateId === item.id
          },
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.onSelect(item);
            }
          }
        }, [_c('div', {
          staticClass: "mb-0"
        }, [_c('i', {
          staticClass: "fad fa-fw mr-1 text-primary",
          class: `${item.icon}`
        }), _vm._v(" " + _vm._s(item.title))])])];
      }
    }])
  })], 1), _c('div', [_c('div', {
    staticClass: "font-w600 font-size-h5 mb-3"
  }, [_c('i', {
    staticClass: "fa fa-users text-primary mr-2"
  }), _vm._v(" Company Templates")]), Object.values(_vm.companyTemplates).flat().length === 0 ? _c('div', {
    staticClass: "alert alert-warning py-2 px-3"
  }, [_vm._v("No Templates")]) : _c('VerticalPills', {
    attrs: {
      "items": _vm.companyTemplates,
      "item-key": "_id",
      "groups": _vm.groups.company,
      "alt": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('a', {
          staticClass: "nav-link",
          class: {
            active: _vm.selectedTemplateId === item.id
          },
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.onSelect(item);
            }
          }
        }, [_c('div', {
          staticClass: "mb-0"
        }, [_c('i', {
          staticClass: "fa fa-fw mr-1 text-secondary",
          class: `${item.icon}`
        }), _vm._v(" " + _vm._s(item.title))])])];
      }
    }])
  })], 1)]), _c('div', {
    staticClass: "col-xl-5 col-lg-6"
  }, [_vm.selectedTemplateId ? _c('div', [_c('SectionTitle', [_vm._v(_vm._s(_vm.selectedTemplate.name))]), _c('div', {
    staticClass: "bg-lightest p-3 rounded-md"
  }, [_c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-list",
      "label": "Category",
      "capitalize": "",
      "blank": ""
    }
  }, [_vm._v(_vm._s(_vm.selectedTemplate.category) + " ")]), _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-user",
      "label": "Created By"
    }
  }, [_vm._v(_vm._s(_vm._f("user")(_vm.selectedTemplate.userSub, _vm.users)) + " ")]), _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-tag",
      "label": "Type"
    }
  }, [_vm._v(_vm._s(_vm._f("capitalize")(_vm.selectedTemplate.type)) + " ")])], 1), _c('p', {
    staticClass: "mt-3"
  }, [_vm._v(_vm._s(_vm.selectedTemplate.description))]), _c('div', {
    staticClass: "d-flex justify-content-between mt-5"
  }, [_c('div', [_vm.selectedTemplate.type === 'dynamic' ? _c('button', {
    staticClass: "btn btn-text-primary mr-2",
    on: {
      "click": _vm.onClickUseTemplate
    }
  }, [_c('i', {
    staticClass: "fa fa-cogs mr-2"
  }), _vm._v(" Use Template ")]) : _vm._e(), _c('button', {
    staticClass: "btn btn-text-secondary mr-2",
    on: {
      "click": _vm.onClickSchedule
    }
  }, [_c('i', {
    staticClass: "fa fa-calendar-clock mr-2"
  }), _vm._v(" Schedule")]), _c('button', {
    staticClass: "btn btn-text-secondary mr-2",
    on: {
      "click": _vm.onClickDuplicate
    }
  }, [_c('i', {
    staticClass: "fa fa-clone mr-2"
  }), _vm._v(" Duplicate")])]), _c('div', [_vm.selectedTemplate.userSub === _vm.$auth.user.sub ? _c('button', {
    staticClass: "btn btn-text-danger",
    on: {
      "click": function ($event) {
        _vm.deleteModal = true;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-trash mr-2"
  }), _vm._v("Delete ")]) : _vm._e()])])], 1) : _vm._e()])]), _c('Modal', {
    attrs: {
      "id": "delete",
      "open": !!_vm.deleteModal,
      "title": "Delete Template"
    },
    on: {
      "close": function ($event) {
        _vm.deleteModal = false;
      },
      "submit": _vm.onDeleteTemplate
    }
  }), _c('Modal', {
    attrs: {
      "id": "schedule",
      "open": _vm.modals.schedule.show,
      "title": "Schedule Report",
      "text": "Send out this report to users on a regular or one-off basis. You can edit scheduled reports at any time by going to the Reports > Schedule tab once created.",
      "lg-size": "",
      "loading": _vm.modals.schedule.loading
    },
    on: {
      "close": function ($event) {
        _vm.modals.schedule.show = false;
      },
      "submit": _vm.onScheduleReport
    }
  }, [_vm.modals.schedule.show ? _c('ScheduledReportForm', {
    model: {
      value: _vm.modals.schedule.form,
      callback: function ($$v) {
        _vm.$set(_vm.modals.schedule, "form", $$v);
      },
      expression: "modals.schedule.form"
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }